@keyframes blink {
    0% {
        background-color: transparent;
    }
    50% {
        background-color: #95aae1;
    }
    100% {
        background-color: transparent;
    }
}

.blink {
    animation: blink 2s ease-in-out;
}
